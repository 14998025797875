import './App.scss';
import emailKey from './emailkey'
import emailjs from 'emailjs-com'
import logo from './assets/logo.png'
import leaf from './assets/leaf.png'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faTwitter, } from '@fortawesome/free-brands-svg-icons'
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'


function App() {
  const [onClic, setonClic] = useState(false)
  const [validate, setvalidate] = useState(false)
  const [validateFail, setvalidateFail] = useState(false)
  const [name, setName] = useState(null)
  const [email, setemail] = useState(null)
  const [nameError, setNameError] = useState(null)
  const [emailError, setEmailError] = useState(null)

  const handleSubmit = () => {
    emailjs.send('service_lcyd86x', emailKey.TEMPLATE_ID, {
      name: name,
      email: email
    }, emailKey.USER_ID)
      .then(function (response) {
        setonClic(false)
        setvalidate(true)
      }, function (error) {
        setonClic(false)
        setvalidateFail(true)
      });
  };
  const submitBtn = async () => {
    setonClic(true)
    setvalidateFail(false)
    setNameError(null)
    setEmailError(null)
    if (!name && !email) {
      setEmailError('Please enter your email!')
      setNameError('Please enter your name!')
      setonClic(false)
      setvalidateFail(true)
    }
    else if (!email) {
      setEmailError('Please enter your email!')
      setonClic(false)
      setvalidateFail(true)
    }
    else if (!name) {
      setNameError('Please enter your name!')
      setonClic(false)
      setvalidateFail(true)
    }
    else if (email && !validateEmail(email)) {
      setEmailError('Ex: name@example.com')
      setonClic(false)
      setvalidateFail(true)
    }
    else if (name && email && validateEmail(email)) { handleSubmit(); setNameError(null); setEmailError(null) }

  }
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  return (
    <div className="App">
      <img alt='Leaf Artifact On the Background' src={leaf} className='left-leaf' />
      <div className='right-artifact'></div>
      <div className='logo-wrapper'>
        <img className='logo' alt='logo' src={logo} />
      </div>
      <div className='socials'>
        <a target='_blank' href="https://www.instagram.com/wearelendoe/"><FontAwesomeIcon icon={faInstagram} /></a>
        <a target='_blank' href="https://twitter.com/weareLendoe"><FontAwesomeIcon icon={faTwitter} /></a>
        <a target='_blank' href="https://www.linkedin.com/company/lendoe/"><FontAwesomeIcon icon={faLinkedin} /></a>
        <a target='_blank' href="mailto:Hello@lendoe.com"><FontAwesomeIcon icon={faEnvelopeOpen} /></a>
      </div>
      <h1>We’re just working on a few things but promise to be back up and running shortly. Leave your email below to be notified as soon as we’re back up & running! Thanks!</h1>
      {/* <h2>Please leave your email to be notified as soon as we’re back up (we promise not to use it for any other purpose)</h2> */}
      <div className='contact-form'>
        <div className='row'>
          <span>
            <input onChange={(e) => { setName(e.target.value) }} className="basic-slide" id="name" type="text" placeholder="Enter your name" /><label for="name">Name</label><br></br><div className='error-wrapp'><b>{nameError ? nameError : "\u00A0"}</b></div>
          </span>
          <span className={'space'} />
          <span>
            <input onChange={(e) => { setemail(e.target.value) }} className="basic-slide" id="email" type="text" placeholder="name@example.com" /><label for="email">Email</label><br></br><div className='error-wrapp'><b>{emailError ? emailError : "\u00A0"}</b></div>
          </span>

          <span className={'space'} />
          <span className="container">
            <button disabled={(validate || onClic) && true} onClick={() => {
              if ((validate || onClic)) { }
              else { submitBtn() }
            }} className={`${onClic && 'onclic'} ${validate && 'validate'} ${validateFail && 'validateFail'}`} id="button"></button><br></br><b>&nbsp;</b>
          </span>
        </div></div>
    </div>
  );
}

export default App;
